import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "ok-text": "Save", "title": "Add New Cost Bucket", "after-close": _vm.afterModalClose, "width": 520 }, on: { "ok": _vm.submit }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.onCancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v("Save")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("select-input", { attrs: { "label": "Cost Bucket", "placeholder": "Type Cost Bucket", "reference": "web-analytics.common.ppv-cost-bucket", "source": "bucket", "source-label": "bucketLongDesc", "rules": "required", "form-item": "", "default-active-first-option": "" }, on: { "change": _vm.onCostBucketChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("checkbox-input", { attrs: { "label": "Include in PPV", "value": true, "disabled": true } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CreateCostsBuckets",
  inject: ["crud", "redirectRoute"],
  data() {
    return {
      apiUrl: apiUrl$1,
      visible: false,
      isLoading: false,
      costBucket: "",
      updateType: "",
      includePPV: 0
    };
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    async submit() {
      this.isLoading = true;
      await this.axios.put(`${this.apiUrl}/web-analytics/cost-buckets`, {
        costBucket: this.costBucket,
        includeinPPV: 1,
        updateType: "add"
      }).then(() => {
        this.isLoading = false;
        this.visible = false;
        this.crud.fetchList();
        this.$notification.success({
          message: "Item Created!"
        });
      }).catch(() => {
        this.isLoading = false;
      });
    },
    afterModalClose() {
      this.$router.push(this.redirectRoute);
    },
    onCancel() {
      this.visible = false;
    },
    onCostBucketChange(value) {
      this.costBucket = value;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateCostBuckets = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.common.ppv-cost-bucket" } }), _c("resource", { attrs: { "name": "web-analytics.cost-buckets", "resource-id-name": "costID", "api-url": _vm.apiUrl, "redirect-route": "/web-analytics/cost-buckets" } }, [_c("create-cost-buckets")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateCostBuckets
  },
  data() {
    return {
      CreateCostBuckets,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
